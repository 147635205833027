<template>
  <FormBuilder :isTemplate="true" />
</template>

<script>
import FormBuilder from './FormBuilder/FormBuilder.vue'

export default {
  components: { FormBuilder }
}
</script>