<template>

  <div class="modal-header">
    <h1 class="modal-title fs-5" id="exampleModalLabel">{{title}}</h1>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <div>

      <material-select  id="text"  class="mb-3" :isRequired="true" :success="field_validation.category" :error="field_validation_err.category" :value="field.category" @change="field.category = $event.target.value;validate()" :options="category_options"  label="Category" />
      <material-input  id="text" class="mb-3" :isRequired="true" :success="field_validation.label" :error="field_validation_err.label" :value="field.label" @change="field.label = $event.target.value;validate()" label="Label" />
      <material-input  id="text" class="mb-3" :isRequired="true" :success="field_validation.name" :error="field_validation_err.name" :value="field.name" @change="field.name = $event.target.value;validate()" label="Name" />
      <material-select id="text" class="mb-3" :isRequired="true" :success="field_validation.type" :error="field_validation_err.type" :value="field.type" @change="field.type = $event.target.value;validate()" :options="type_options" label="Type" />
      <material-select  id="text" class="mb-3" :isRequired="true" :success="field_validation.ghl_module" :error="field_validation_err.ghl_module" :value="field.ghl_module" @change="field.ghl_module = $event.target.value;validate()" :options="module_options" label="GHL Module" />
        </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
    <button type="button" class="btn btn-primary" @click="create()">Save</button>
  </div>

</template>
<script >
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialSelect from "@/components/MaterialSelect.vue";
export default {
  name: 'Add Field',
  components: {MaterialSelect, MaterialInput },
  props: ['title', 'init_field'],
  emits:['save'],
  data () {
    return {
      field:{
        category: '',
        label: '',
        name: '',
        type:'',
        ghl_module: '',
      },
      field_validation:{
        category: null,
        label: null,
        name: null,
        type:null,
        ghl_module: null,
      },
      field_validation_err:{
        category: null,
        label: null,
        name: null,
        type:null,
        ghl_module: null,
      },
      category_options:[
          'Borrower Information',
          'Borrower History',
          'Borrower Entity Information',
          'Investor Source',
          'Project Summary',
          'Collateral Information',
          'Borrower Project Summary',
          'Purchase Summary',
          'Refinance Summary',
          'Project Estimated Value',
          'Borrower Loan Request',
          'Title Company Information',
          'Insurance Company Information',
          'Borrower Conditions',
          'Processing & Underwriting',
          'Referral Terms'
      ],
      type_options: [
          'Text',
          'Date Select',
          'Currency',
          'Dropdown',
          'Text/percentage',
          'Lookup/text',
          'Checkbox'
      ],
      module_options: [
          'Opportunity',
          'Contacts'
      ],
    }
  },
  methods:{
    create: function () {
      console.log('save field')
      if(!this.validate()){
        return;
      }
      console.log('emit save event')
      this.$emit('save',this.field);
    },
    validate: function (){
      let valid = true;
      if(this.field.category.length < 2){
        this.field_validation.category = false;
        this.field_validation_err.category = true;
        valid = false;
      }else{
        this.field_validation.category = true;
        this.field_validation_err.category = false;
      }
      if(this.field.name.length < 2){
        this.field_validation.name = false;
        this.field_validation_err.name = true;
        valid = false;
      }else{
        this.field_validation.name = true;
        this.field_validation_err.name = false;
      }
      if(this.field.type.length < 2){
        this.field_validation.type = false;
        this.field_validation_err.type = true;
        valid = false;
      }else{
        this.field_validation.type = true;
        this.field_validation_err.type = false;
      }
      if(this.field.ghl_module.length < 2){
        this.field_validation.ghl_module = false;
        this.field_validation_err.ghl_module = true;
        valid = false;
      }else{
        this.field_validation.ghl_module = true;
        this.field_validation_err.ghl_module = false;
      }

      return valid;
    }
  },
  watch:{
    init_field: function (new_field){
      console.log('watcher ')
      if(new_field){
        this.field = new_field
      }else{
        this.field = {
          category: '',
          label: '',
          name: '',
          type:'',
          ghl_module: '',
        }
      }
    }
  },
  mounted() {
  }
}
</script>
<style scoped>

</style>

