<template>
  <div class="container-fluid mt-4">
    <div class="row align-items-center">
      <div class="col-lg-4 col-sm-8">
        <nav-pill-system-settings />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="row mt-4">
          <div class="col-md-12 mb-4">
            <form-fields-card />
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>

import NavPillSystemSettings from "@/views/components/NavPillSystemSettings.vue";
import FormFieldsCard from "@/views/components/FormsFieldsCard.vue";

export default {
  name: "Settings",
  components: {
    FormFieldsCard,
    NavPillSystemSettings,
  },
};
</script>
