import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import SubAccounts from '../views/Subaccounts.vue'
import FormBuilderPage from '../views/FormBuilderPage.vue'
import TemplateBuilderPage from '../views/TemplateBuilderPage.vue'
import FormsList from '../views/FormsList.vue'
import TemplatesList from '../views/TemplatesList.vue'
import AddSubAccount from '@/views/Subaccounts/AddSubAccount.vue'
import Billing from '../views/Billing.vue'
import RTL from '../views/Rtl.vue'
import Notifications from '../views/Notifications.vue'
import Profile from '../views/Profile.vue'
import SignIn from '../views/SignIn.vue'
import SignUp from '../views/SignUp.vue'
import Settings from '@/views/Settings.vue'

const routes = [
  {
    path: '/',
    name: '/',
    redirect: '/dashboard',
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/sub-accounts',
    name: 'Sub Accounts',
    component: SubAccounts,
  },
  {
    path: '/sub-accounts/add',
    name: 'Add New Sub Account',
    component: AddSubAccount,
  },
  {
    path: '/form-builder/:formId?',
    name: 'Form Builder',
    component: FormBuilderPage,
  },
  {
    path: '/template-builder/:formId?',
    name: 'Template Builder',
    component: TemplateBuilderPage,
  },
  {
    path: '/forms',
    name: 'Forms',
    component: FormsList,
  },
  {
    path: '/templates',
    name: 'Templates',
    component: TemplatesList,
  },
  {
    path: '/billing',
    name: 'Billing',
    component: Billing,
  },
  {
    path: '/settings',
    name: 'System Settings',
    component: Settings,
  },
  {
    path: '/rtl-page',
    name: 'RTL',
    component: RTL,
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    component: SignIn,
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active',
})

export default router
