<template>
  <div class="section-container">
    <header><IconClose @click="handleRemove"/></header>
    <Draggable>
      <div class="section-content-container">
        <h1>{{ section.title }}</h1>
        <p>{{ section.description }}</p>
        <div class="inputs">
          <label :for="`title-${section.id}`">Title</label>
          <material-input 
            type="text" 
            :id="`title-${section.id}`"
            :value="section.title"
            @input="ev => {
              handleDataChange({ title: ev.target.value })
            }"
            placeholder="Title"
          />

          <label :for="`description-${section.id}`">Description</label>
          <textarea
            :id="`description-${section.id}`"
            :value="section.description"
            class="form-control" 
            @input="ev => {
              handleDataChange({ description: ev.target.value })
            }"
            placeholder="Description"
          />
        </div>
        <h2>Fields</h2>
        <draggable 
          class="fields-container"
          v-model="fields"
          group="section"
          item-key="type"
          @add="handleFieldAdd"
          @sort="handleFieldsSort"
        >
          <template #item="{ element, index }">
            <div>
              <FormField 
                :el="element" 
                :i="index"
                @remove="handleFieldRemove"
                @change="handleFieldChange"
                @option-remove="handleFieldOptionRemove"
              />
            </div>
          </template>
        </draggable> 
      </div>
    </Draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import MaterialInput from '@/components/MaterialInput.vue'

import IconClose from './icons/IconClose.vue';
import Draggable from './Draggable.vue';
import FormField from './FormField.vue';

export default {
  props: ['section'],
  emits: ['change', 'remove'],
  data: () => ({
    fields: []
  }),
  methods: {
    handleDataChange(data) {
      this.$emit('change', this.section.id, data)
    },

    handleRemove() {
      this.$emit('remove', this.section.id)
    },

    handleFieldAdd() {
      this.$emit('change', this.section.id, null, this.fields)
    },

    handleFieldsSort() {
      this.$emit('change', this.section.id, null, this.fields)
    },

    handleFieldRemove(fieldId) {
      const fieldI = this.fields.map(el => el.id).indexOf(fieldId)
      this.fields.splice(fieldI, 1)

      this.$emit('change', this.section.id, null, this.fields)
    },

    handleFieldChange(data, id) {
      const i = this.fields.map(el => el.id).indexOf(id)
      if (i === -1) return

      const el = this.fields[i]

      if (data.options) {
        if (!el.options) {
          el.options = [data.options]
        } else {
          el.options.push(data.options)
        }

        return
      }

      this.fields[i] = { ...el, ...data }

      this.$emit('change', this.section.id, null, this.fields)
    },

    handleFieldOptionRemove(option, id) {
      const i = this.fields.map(el => el.id).indexOf(id)
      if (i === -1) return

      const el = this.fields[i]

      const optionI = el.options.indexOf(option)
      if (optionI === -1) return

      el.options.splice(optionI, 1)

      this.$emit('change', this.section.id, null, this.fields)
    }
  },
  beforeMount() {
    this.fields = this.section.fields
  },
  components: { 
    draggable, 
    MaterialInput, 
    IconClose, 
    Draggable, 
    FormField 
  }
}
</script>

<style scoped>
.section-container {
  border-radius: 4px;
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12);
}

header {
  display: flex;
  justify-content: flex-end;
}

.section-container {
  background-color: white;
}

.section-content-container {
  margin: 6px;
  padding: 8px;
  border: 1px solid rgba(0,0,0, 0.2);
  border-radius: 4px;
}

.inputs {
  display: grid;
  grid-template-columns: 150px 1fr;

  align-items: center;
  row-gap: 8px;

  margin-bottom: 8px;
}

.fields-container {
  min-height: 150px;
}
</style>