<template>
  <div ref='signatureContainer' class="signature-container">
    <canvas
      class="signature"
      ref="signature"
      :height="height || 150"
    ></canvas>
    <div class="signature__button-container">
      <MaterialButton ref="signatureClear">Clear</MaterialButton>
    </div>
  </div>
</template>

<script>
import SignaturePad from "signature_pad"
import MaterialButton from '@/components/MaterialButton.vue'

export default {
  props: ['height'],
  mounted() {
    if (!this.$refs.signature || !this.$refs.signatureClear || !this.$refs.signatureContainer) return

    const containerWidth = this.$refs.signatureContainer.getBoundingClientRect().width
    this.$refs.signature.setAttribute('width', containerWidth)

    const pad = new SignaturePad(this.$refs.signature, {
      backgroundColor: 'rgba(0,0,0, 0.1)'
    })

    this.$refs.signatureClear.$el.addEventListener('click', () => {
      pad.clear()
    })
  },
  components: { MaterialButton }
}
</script>

<style scoped>
.signature__button-container {
  display: flex;
  justify-content: flex-end;
}
</style>