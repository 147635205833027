<template>
  <div class="input-container">
    <slot></slot>
    <label :for="forInput">
    {{ labelText }}
    </label>
  </div>
</template>

<script>
export default {
  props: ['forInput', 'labelText']
}
</script>

<style scoped>
.input-container {
  display: flex;
  flex-direction: column;

  row-gap: 8px;
}
</style>