<template>

  <div class="modal-header">
    <h1 class="modal-title fs-5" id="exampleModalLabel">Edit Subaccount</h1>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <div>
      <material-input  id="text"  class="mb-3" :isRequired="true" :success="sub_account_validation.title" :error="sub_account_validation_err.title" :value="sub_account.title" @change="sub_account.title = $event.target.value;validate()"  label="Sub Account Title" />
      <material-input  id="text" class="mb-3" :isRequired="true" :success="sub_account_validation.first_name" :error="sub_account_validation_err.first_name" :value="sub_account.first_name" @change="sub_account.first_name = $event.target.value;validate()" label="First Name" />
      <material-input  id="text" class="mb-3" :isRequired="true" :success="sub_account_validation.last_name" :error="sub_account_validation_err.last_name" :value="sub_account.last_name" @change="sub_account.last_name = $event.target.value;validate()" label="Last Name" />
      <material-input  id="text" class="mb-3" :isRequired="true" :success="sub_account_validation.email" :error="sub_account_validation_err.email" :value="sub_account.email" @change="sub_account.email = $event.target.value;validate()" label="Email" />
      <material-input  id="text" class="mb-3" :isRequired="true" :success="sub_account_validation.company" :error="sub_account_validation_err.company" :value="sub_account.company" @change="sub_account.company = $event.target.value;validate()" label="Company" />
      <material-input  id="text" class="mb-3" :isRequired="true" :success="sub_account_validation.address_line_1" :error="sub_account_validation_err.address_line_1" :value="sub_account.address_line_1" @change="sub_account.address_line_1 = $event.target.value;validate()" label="Address Line 1" />
      <material-input  id="text" class="mb-3" :isRequired="false" :success="sub_account_validation.address_line_2" :error="sub_account_validation_err.address_line_2" :value="sub_account.address_line_2" @change="sub_account.address_line_2 = $event.target.value;validate()" label="Address Line 2" />

      <material-input  id="text" class="mb-3" :isRequired="true" :success="sub_account_validation.city" :error="sub_account_validation_err.city" :value="sub_account.city" @change="sub_account.city = $event.target.value;validate()" label="City" />
      <material-input  id="text" class="mb-3" :isRequired="true" :success="sub_account_validation.state" :error="sub_account_validation_err.state" :value="sub_account.state" @change="sub_account.state = $event.target.value;validate()" label="State" />
      <material-input  id="text" class="mb-3" :isRequired="true" :success="sub_account_validation.zip" :error="sub_account_validation_err.zip" :value="sub_account.zip" @change="sub_account.zip = $event.target.value;validate()" label="ZIP" />
      <material-input  id="text" class="mb-3" :isRequired="true" :success="sub_account_validation.country" :error="sub_account_validation_err.country" :value="sub_account.country" @change="sub_account.country = $event.target.value;validate()" label="Country" />
      <material-input  id="text" class="mb-3" :isRequired="true" :success="sub_account_validation.phone" :error="sub_account_validation_err.phone" :value="sub_account.phone" @change="sub_account.phone = $event.target.value;validate()" label="Phone" />
<!--      <material-input  id="text" class="mb-3" @change="sub_account.loan_id = $event.target.value" :value="sub_account.loan_id" label="Loan ID" />-->


    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
    <button type="button" class="btn btn-primary" @click="update()">Update</button>
  </div>

</template>
<script >
import MaterialInput from "@/components/MaterialInput.vue";
import axios from 'axios';
export default {
  name: 'Sub Account',
  components: { MaterialInput },
  props: ['sub_account_data'],
  data () {
    return {
      sub_account:{
        title: '',
        first_name: '',
        last_name: '',
        email:'',
        company: '',
        address_line_1:'',
        address_line_2:'',
        city:'',
        state:'',
        zip:'',
        country:'',
        phone: '',
        loan_id:'',
        api_key: '1234',
      },
      sub_account_validation:{
        title: null,
        first_name: null,
        last_name: null,
        email:null,
        company: null,
        address_line_1:null,
        address_line_2:null,
        city:null,
        state:null,
        zip:null,
        country:null,
        phone: null,
        loan_id:null,
        api_key: null,
      },
      sub_account_validation_err:{
        title: null,
        first_name: null,
        last_name: null,
        email:null,
        company: null,
        address_line_1:null,
        address_line_2:null,
        city:null,
        state:null,
        zip:null,
        country:null,
        phone: null,
        loan_id:null,
        api_key: null,
      }
    }
  },
  methods:{
    update: function () {

      if(!this.validate()){
        return;
      }
      axios.post('http://ghlapp.local/api/sub-accounts/'+this.sub_account.id,this.sub_account).then(function (response) {
        console.log(response);
      })
          .catch(function (error) {
            console.log(error);
          })
          .finally(function () {
            // always executed
          });
    },
    validate: function (){
      let valid = true;
      if(this.sub_account.title.length < 2){
        this.sub_account_validation_err.title = true;
        this.sub_account_validation.title = false;
        valid = false;
      }else{
        this.sub_account_validation.title = true;
        this.sub_account_validation_err.title = false;
      }
      if(this.sub_account.city.length < 2){
        this.sub_account_validation.city = false;
        this.sub_account_validation_err.city = true;
        valid = false;
      }else{
        this.sub_account_validation.city = true;
        this.sub_account_validation_err.city = false;

      }
      if(this.sub_account.zip.length < 2){
        this.sub_account_validation.zip = false;
        this.sub_account_validation_err.zip = true;

        valid = false;
      }else{
        this.sub_account_validation.zip = true;
        this.sub_account_validation_err.zip = false;

      }
      if(this.sub_account.address_line_1.length < 2){
        this.sub_account_validation.address_line_1 = false;
        this.sub_account_validation_err.address_line_1 = true;

        valid = false;
      }else{
        this.sub_account_validation.address_line_1 = true;
        this.sub_account_validation_err.address_line_1 = false;

      }
      if(this.sub_account.phone.length < 2){
        this.sub_account_validation.phone = false;
        this.sub_account_validation_err.phone = true;

        valid = false;
      }else{
        this.sub_account_validation.phone = true;
        this.sub_account_validation_err.phone = false;

      }
      if(this.sub_account.country.length < 2){
        this.sub_account_validation.country = false;
        this.sub_account_validation_err.country = true;

        valid = false;
      }else{
        this.sub_account_validation.country = true;
        this.sub_account_validation_err.country = false;

      }
      if(this.sub_account.state.length < 2){
        this.sub_account_validation.state = false;
        this.sub_account_validation_err.state = true;

        valid = false;
      }else{
        this.sub_account_validation.state = true;
        this.sub_account_validation_err.state = false;

      }
      if(this.sub_account.first_name.length < 2){
        this.sub_account_validation.first_name = false;
        this.sub_account_validation_err.first_name = true;

        valid = false;
      }else{
        this.sub_account_validation.first_name = true;
        this.sub_account_validation_err.first_name = false;

      }
      if(this.sub_account.last_name.length < 2){
        this.sub_account_validation.last_name = false;
        this.sub_account_validation_err.last_name = true;

        valid = false;
      }else{
        this.sub_account_validation.last_name = true;
        this.sub_account_validation_err.last_name = false;

      }
      if(this.sub_account.email.length < 2 || this.sub_account.email.indexOf('@') < 1){
        this.sub_account_validation.email = false;
        this.sub_account_validation_err.email = true;

        valid = false;
      }else{
        this.sub_account_validation.email = true;
        this.sub_account_validation_err.email = false;

      }
      if(this.sub_account.company.length < 2){
        this.sub_account_validation.company = false;
        this.sub_account_validation_err.company = true;

        valid = false;
      }else{
        this.sub_account_validation.company = true;
        this.sub_account_validation_err.company = false;

      }
      return valid;
    }
  },
  created() {
    this.sub_account = this.sub_account_data;
    this.sub_account.email = this.sub_account_data.user.email;
  },
  updated() {
    this.sub_account = this.sub_account_data;
    this.sub_account.email = this.sub_account_data.user.email;
  }
}
</script>
<style scoped>

</style>
