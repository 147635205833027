<template>
  <div class="box">
    <div class="content">
      <slot></slot>
    </div>
    <div class="drag-pad">
      <IconDraggable />
    </div>
  </div>
</template>

<script>
import IconDraggable from './icons/IconDraggable.vue';

export default {
  components: {IconDraggable}
}
</script>

<style scoped>
.box {
  display: flex;
}

.content {
  flex-grow: 1;
}

.drag-pad {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 30px;
  cursor: grab;
}
</style>