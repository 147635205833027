<template>
  <div class="address-container">
    <InputWithLabel 
      class="full-width"
      :forInput="`${inputIdPrefix}_street`"
      labelText="Entity Street Adress"
    >
      <material-input 
        type="text"
        :id="`${inputIdPrefix}_street`"
      ></material-input>
    </InputWithLabel>

    <InputWithLabel 
      class="full-width"
      :forInput="`${inputIdPrefix}_street2`"
      labelText="Entity Address Line 2"
    >
      <material-input 
        type="text"
        :id="`${inputIdPrefix}_street2`"
      ></material-input>
    </InputWithLabel>

    <InputWithLabel
      :forInput="`${inputIdPrefix}_city`"
      labelText="Entity City"
    >
      <material-input 
        type="text"
        :id="`${inputIdPrefix}_city`"
      ></material-input>
    </InputWithLabel>

    <InputWithLabel
      :forInput="`${inputIdPrefix}_region`"
      labelText="Entity State/Region/Province"
    >
      <material-input 
        type="text"
        :id="`${inputIdPrefix}_region`"
      ></material-input>
    </InputWithLabel>

    <InputWithLabel
      :forInput="`${inputIdPrefix}_zip`"
      labelText="Entity Postal / Zip Code"
    >
      <material-input 
        type="text"
        :id="`${inputIdPrefix}_zip`"
      ></material-input>
    </InputWithLabel>
    <InputWithLabel
      :forInput="`${inputIdPrefix}_country`"
      labelText="Entity Country"
    >
      <select :id="`${inputIdPrefix}_country`" class="form-select">
        <option value="">Select a country</option>
        <option value="USA">USA</option>
        <option value="Switzerland">Switzerland</option>
        <option value="Canada">Canada</option>
      </select>
    </InputWithLabel>
  </div>
</template>

<script>
import MaterialInput from '@/components/MaterialInput.vue'
import InputWithLabel from './InputWithLabel.vue'

export default {
  props: ['inputIdPrefix'],
  components: { MaterialInput, InputWithLabel }
}
</script>

<style scoped>
.address-container {
  display: grid;
  grid-template-columns: 1fr 1fr;

  column-gap: 8px;
  row-gap: 8px;
}

.full-width {
  grid-column: 1 / 3;
}
</style>