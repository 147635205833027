<template>
  <div>
    <header><IconClose @click="$emit('remove', el.id)"/></header>
    <Draggable>
      <h1>Type: {{ el.type }}</h1>

        <h2>Data</h2>
        <div class="inputs">
          <label :for="`required-${el.id}`">Required</label>
          <material-checkbox 
            :id="`required-${el.id}`"
            :checked="typeof el.required === 'boolean' ? el.required : false"
            @change="ev => {
              handleChange({ required: !!ev.target.checked })
            }"
          >
            Required
          </material-checkbox>

          <label :for="`label-${el.id}`">Label</label>
          <material-input 
            type="text" 
            :id="`label-${el.id}`"
            :value="el.label || ''"
            @input="ev => {
              handleChange({ label: ev.target.value })
            }"
            placeholder="Label"
          />

          <template v-if="['text', 'number', 'tel', 'email', 'url', 'textarea', 'select'].includes(el.type)">
            <label :for="`placeholder-${el.id}`">Placeholder</label>
            <material-input 
              type="text" 
              :id="`placeholder-${el.id}`"
              :value="el.placeholder || ''"
              @input="ev => {
                handleChange({ placeholder: ev.target.value })
              }"
              placeholder="Placeholder"
            />
          </template>

          <template v-if="['radio', 'checkbox_group', 'select'].includes(el.type)">
            <label :for="`option-${el.id}`">Options</label>
            <div class="options-menu-container">
              <div class="option-input-container">
                <material-input 
                  ref="optionInput"
                  type="text"
                  :id="`option-${el.id}`"
                  :value="optionInputValue"
                  @change="handleOptionInputValueChange"
                  @keyup="ev => {
                    // for .isComposing see https://developer.mozilla.org/en-US/docs/Web/API/Element/keyup_event
                    if (ev.isComposing || ev.code !== 'Enter') return

                    handleOptionInputValueChange(ev)
                    handleChange({ options: ev.target.value })
                    optionInputValue = ''
                  }"
                  placeholder="Add new option"
                />
                <MaterialButton 
                  @click="() => {
                    if (!$refs) return

                    handleChange({ options: $refs.optionInput.value })
                    optionInputValue = ''
                  }"
                >
                  Add option
                </MaterialButton>
              </div>
              <ul class="options-editable-container">
                <li 
                  v-for="option in el.options" 
                  :key="option"
                  class="option-editable"
                >
                  <span class="option-name">{{ option }}</span> <IconClose @click="ev => {handleOptionRemove(option)}"/>
                </li>
              </ul>
            </div>
          </template>

          <template v-if="el.type === 'terms'">
            <label :for="`terms-${el.id}`">Terms and Conditions</label>
            <textarea 
              :id="`terms-${el.id}`" 
              class="form-control" 
              :value="el.text || ''"
              @input="ev => {
                handleChange({ text: ev.target.value })
              }"
              placeholder="Terms and Conditions"
            />
          </template>
        </div>

        <h2>Preview</h2>
        <div class="demo">
          <h2 v-if="el.label && el.type !== 'Checkbox'">{{ el.required ? `${el.label} *` : el.label }}</h2>

          <InputForm 
            :id="el.id"
            :type="el.type"
            :placeholder="el.placeholder || null"
            :label="el.type === 'Checkbox' && el.label"
            :options="el.options || null"
            :text="el.text || null"
          />
        </div>
    </Draggable>
  </div>
</template>

<script>
import MaterialInput from '@/components/MaterialInput.vue'
import MaterialCheckbox from '@/components/MaterialCheckbox.vue'
import MaterialButton from '@/components/MaterialButton.vue'

import IconClose from './icons/IconClose.vue';
import Draggable from './Draggable.vue'
import InputForm from './InputForm.vue';

export default {
  props: ['el', 'i'],
  emits: ['remove', 'change', 'option-remove'],
  data: () => ({
    optionInputValue: '',
    isInitialNameSelected: false,
  }),
  methods: {
    handleChange(data) {
      this.$emit('change', data, this.el.id)
    },
    handleOptionRemove(option) {
      this.$emit('option-remove', option, this.el.id)
    },
    handleOptionInputValueChange(ev) {
      this.optionInputValue = ev.target.value
    }
  },
  components: { 
    MaterialInput, 
    MaterialCheckbox,
    MaterialButton, 
    InputForm, 
    IconClose, 
    Draggable, 
  }
}

</script>

<style scoped>
header {
  display: flex;
  justify-content: flex-end;
}

.inputs {
  display: grid;
  grid-template-columns: 150px 1fr;

  align-items: center;

  margin-bottom: 8px;
}

.options-menu-container {
  display: flex;
  flex-direction: column;

  row-gap: 8px;
}

.option-input-container {
  display: flex;
  column-gap: 8px;

  button {
    min-width: 150px;
  }
}

.options-editable-container {
  margin: 0;
  padding: 0;

  list-style-type: none;

  .option-editable {
    display: flex;

    .option-name {
      flex-grow: 1;
    }
  }
}

h1 {
  font-size: 22px;
}

h2 {
  font-size: 18px;
}
</style>