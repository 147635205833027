<template>
  <div
    class="input-group"
    :class="`input-group-outline ${getStatus(error, success)}`"
  >
    <label :class="variant === 'static' ? '' : 'form-label'">{{ label }}</label>
    <select
        :id="id"
        class="form-control"
        :class="getClasses(size)"
        :name="name"
        :value="value"
        :disabled="disabled"
        @input="$emit('update:value', $event.target.value)"
    >
      <option disabled value="">Please select one</option>
      <option v-for="( option, index) in options" :value="option" :key="index">{{option}}</option>
    </select>

  </div>
</template>

<script>
import setMaterialInput from "@/assets/js/material-input.js";

export default {
  name: "MaterialSelect",
  props: [
    'variant',
    'label',
    'size',
    'success',
    'error',
    'disabled',
    'name',
    'id',
    'value',
    'placeholder',
    'model',
    'isRequired',
    'options'
  ],
  data(){
    return{

    }
  },
  emits: ["update:value"],
  mounted() {
    setMaterialInput();
  },
  methods: {
    getClasses: (size) => {
      let sizeValue;
      size = 'outline';
      sizeValue = size ? `form-control-${size}` : null;

      return sizeValue;
    },
    getStatus: (error, success) => {
      let isValidValue;

      if (success) {
        isValidValue = "is-valid";
      } else if (error) {
        isValidValue = "is-invalid";
      } else {
        isValidValue = null;
      }

      return isValidValue;
    },
  },
};
</script>
