<template>
  <material-input 
    v-if="type === 'text'" 
    :id="id"
    type="text" 
    :placeholder="placeholder || 'Text'" 
  />

  <material-input 
    v-else-if="type === 'number'" 
    :id="id"
    type="number" 
    :placeholder="placeholder || 'Number'"
  />

  <material-input 
    v-else-if="type === 'tel'" 
    :id="id"
    type="tel" 
    :placeholder="placeholder || 'Telephone'"
  />

  <material-input 
    v-else-if="type === 'email'" 
    :id="id"
    type="email" 
    :placeholder="placeholder || 'Email'"
  />

  <material-input 
    v-else-if="type === 'url'" 
    :id="id"
    type="url" 
    :placeholder="placeholder || 'Url'"
  />

  <material-input 
    v-else-if="type === 'date'"
    :id="id"
    type="date"
  />

  <material-input 
    v-else-if="type === 'file'"
    :id="id"
    type="file"
  />

  <textarea 
    v-else-if="type === 'textarea'" 
    :id="id"
    class="form-control" 
    :placeholder="placeholder || 'Text Area'"
  />

  <material-checkbox 
    v-else-if="type === 'Checkbox'" 
    :id="id"
  >
    {{ label || 'Checkbox' }}
  </material-checkbox>

  <select v-else-if="type === 'select'" :id="id" class="form-select">
    <option value="">{{ placeholder || 'Select an option' }}</option>
    <option 
      v-for="option in options" 
      :key="option"
      :value="option"
    >{{ option }}</option>
  </select>

  <div v-else-if="type === 'checkbox_group'" class="checkbox-group">
    <material-checkbox 
      v-for="option in options"
      :key="option"
      :id="option.replaceAll(/\s/g, '-')"
    >
      {{ option }}
    </material-checkbox>
  </div>

  <div v-else-if="type === 'radio'" class="radio-group">
    <material-radio 
      v-for="option in options"
      :key="option"
      :name="id"
      :id="option.replaceAll(/\s/g, '-')"
    >
      {{ option }}
    </material-radio>
  </div>

  <div v-else-if="type === 'terms'" class="terms">
    <div class="terms__text">
      {{ text }}
    </div>
    <material-checkbox :id="`${id}_form-terms-checkbox`">I Accept Terms and Conditions</material-checkbox>
  </div>

  <Signature 
    v-else-if="type === 'signature'"
    :id="id"
    height="225" 
  />

  <InputAddress 
    v-else-if="type === 'address'"
    :inputIdPrefix="`form_${id}`"
  />

</template>

<script>
import MaterialInput from '@/components/MaterialInput.vue'
import MaterialCheckbox from '@/components/MaterialCheckbox.vue'
import MaterialRadio from '@/components/MaterialRadio.vue'

import Signature from './Signature.vue'
import InputAddress from "./InputAddress.vue"

export default {
  props: ['id', 'type', 'placeholder', 'label', 'options', 'text'],
  components: { 
    MaterialInput, 
    MaterialCheckbox, 
    MaterialRadio, 
    Signature, 
    InputAddress 
  }
}
</script>

<style scoped>
  .terms {
    display: flex;
    flex-direction: column;

    row-gap: 8px;
  }

  .terms__text {
    max-height: 250px;
    overflow-y: auto;
  }
</style>