<template>
  <div class="container-fluid">
    <div class="row d-flex flex-column gap-2">
      <FormCard 
        v-for="form in forms"
        :key="form.id"
        :to="`/form-builder/${form.id}`"
        :form="form"
      />
    </div>
  </div>
</template>

<script>
import axios from '../utils/axios'
import FormCard from './components/FormCard.vue'

export default {
  data: () => ({
    forms: []
  }),
  async beforeMount() {
    try {
      const res = await axios.get('/v1/forms')

      this.forms = res.data
    } catch (e) {
      console.log('FormsList.beforeMount, axios errored, error:', e)
    }
  },
  components: { FormCard }
}
</script>