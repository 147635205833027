<template>
  <div class="card">
    <router-link :to="to">
      <div class="card-header pb-0">
        <h2>{{ form.title }}</h2>
        <hr class="mt-3 mb-0 horizontal dark" />
      </div>
      <div class="card-body">
        <span>Form type:</span> <span>form type</span>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: ['form', 'to']
}
</script>

<style scoped>
.card-body {
  display: grid;
  grid-template-columns: 150px 1fr;

  align-items: center;
}

h2 {
  font-size: 22px;
}
</style>