<template>
  <div class="card">

    <div class="card-header pb-0 px-3">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h6 class="mb-0">Form Fields</h6>
        </div>
        <div class="col-6 text-end">
          <material-button color="dark"  variant="gradient" @click="init_add" data-bs-toggle="modal" data-bs-target="#addFieldModal">
            <i class="fas fa-plus me-2"></i>
            Add New Form Field
          </material-button>
        </div>
      </div>
    </div>
    <div class="card-body pt-4 p-3">
      <div v-for="field_group  in fields" :key="field_group.category">
        <h6>{{field_group.category}}</h6>
        <ul class="list-group">
          <li v-for="field in field_group.fields" :key="field.id"
              class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg"
          >
            <div class="d-flex flex-column">
              <h6 class="mb-3 text-sm">{{ field.label }}</h6>
              <span class="mb-2 text-xs">
              name:
              <span class="text-dark font-weight-bold ms-sm-2"
              >{{field.name}}</span
              >
            </span>
              <span class="mb-2 text-xs">
              type:
              <span class="text-dark ms-sm-2 font-weight-bold"
              >{{ field.type }}</span
              >
            </span>
              <span class="text-xs">
              GHL module:
              <span class="text-dark ms-sm-2 font-weight-bold">{{ field.ghl_module }}</span>
            </span>
            </div>
            <div class="ms-auto text-end">
              <a
                  class="btn btn-link text-danger text-gradient px-3 mb-0"
                  @click="deleteField(field.id)"
              >
                <i class="far fa-trash-alt me-2" aria-hidden="true"></i>Delete
              </a>
              <a class="btn btn-link text-dark px-3 mb-0" @click="init_edit(field, field_group.category)" data-bs-toggle="modal" data-bs-target="#editFieldModal">
                <i class="fas fa-pencil-alt text-dark me-2" aria-hidden="true"></i
                >Edit
              </a>
            </div>
          </li>

        </ul>
      </div>

    </div>
  </div>
  <!-- Modal -->
  <div class="modal fade" id="addFieldModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <AddField title="Add Field"  @save="add_field"></AddField>

      </div>
    </div>
  </div>
  <div class="modal fade" id="editFieldModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <AddField title="Edit Field" :init_field="add_form_field" @save="update_field"></AddField>

      </div>
    </div>
  </div>
  <!-- Toast Alert -->
  <div class="position-fixed bottom-1 end-1 z-index-2">
    <material-snackbar
        v-if="snackbar === 'success'"
        :title="snackbar"
        date="1 mins ago"
        :description="alert_text"
        :icon="{ component: 'done', color: 'white' }"
        color="success"
        :close-handler="closeSnackbar"
    />
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import axios from "axios";
import AddField from "@/views/Settings/AddField.vue";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";

export default {
  name: "form-fields-card",
  data(){
    return {
      add_form_title:'Add Field',
      add_form_field: null,
      alert_text:'',
      snackbar: null,
      fields: []
    }
  },
  components: {MaterialSnackbar, AddField, MaterialButton},
  methods:{
    add_field: function (field){

      for (let fieldset of this.fields){
        console.log('check for ',fieldset.category, field.category)
          if(fieldset.category === field.category){
            let id = field.category.toLowerCase().replaceAll(' ','_') + '_'+field.name;
            fieldset.fields.push({
              id:id,
              label: field.label,
              name: field.name,
              type: field.type,
              ghl_module: field.ghl_module
            })
            this.saveFields();
          }
      }
    },
    update_field: function (field){
      for (let fieldset of this.fields){
        for (let i=0; i <  fieldset.fields.length; i++){
          console.log('check ',fieldset.fields[i].id  )
          if(fieldset.fields[i].id === field.id){
            fieldset.fields[i] = field;
            this.saveFields();
            return;
          }
        }
      }
    },
    saveFields: function (){
      axios.post('http://ec2-3-8-131-126.eu-west-2.compute.amazonaws.com:8009/api/v1/settings/form_fields',
          {
            id: 'form_fields',
            title: 'Form Fields',
            value:  JSON.stringify(this.fields)
          }
         ).then((res)=>{
        document.querySelector('#editFieldModal .btn-close').click();
        document.querySelector('#addFieldModal .btn-close').click();

        this.fields = JSON.parse(res.data.setting.Value)
        this.alert_text='Form fields was saved!'
        this.snackbar = 'success'

      })
    },
    init_add: function (){
      this.add_form_field = null;
      this.add_form_title = 'Add Field';
      },
    init_edit: function (field, category){
      this.add_form_field = {
        id: field.id,
        category: category,
        label: field.label,
        name: field.name,
        type: field.type,
        ghl_module: field.ghl_module,
      };
      this.add_form_title = 'Edit Field';
    },
    closeSnackbar() {
      this.snackbar = null;
    },

    deleteField(id){
      console.log('deleting field ', id)
      for (let fieldset of this.fields){
        console.log(fieldset.fields.length)
        for (let i=0; i <  fieldset.fields.length; i++){
          console.log('check ',fieldset.fields[i].id  )
          if(fieldset.fields[i].id === id){
            fieldset.fields.splice(i,1);
            this.saveFields();
            return;
          }
        }
      }
    },
  },
  mounted() {

     axios.get('http://ec2-3-8-131-126.eu-west-2.compute.amazonaws.com:8009/api/v1/settings/form_fields').then((res)=>{
       this.fields = JSON.parse(res.data.Value);
     })
  }
};
</script>
