<template>
  <div class="category-container">
    <h2 class="category-name">{{ name }}</h2>
    <draggable 
      class="draggable-container d-flex flex-column gap-2" 
      v-model="fields"
      :group="{name: 'section', pull: 'clone', put: false}"
      :sort="false"
      :clone="handleClone"
      item-key="type" 
    >
      <!-- wrap content in a single root element: https://github.com/SortableJS/vue.draggable.next/issues/24#issuecomment-859156290 -->
      <template #item="{element}">
        <div class="field-container">
          <Draggable>
            <h3 class="field-label">{{ element.label }}</h3>
            <!-- <InputSource :el="element" :i="index" /> -->
          </Draggable>
        </div>
      </template>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import Draggable from '../../components/Draggable.vue'
// import InputSource from '../../components/InputSource.vue'

export default {
  props: ['name', 'fieldsProp', 'handleClone'],
  data: () => ({
    fields: []
  }),
  beforeMount() {
    this.fields = this.fieldsProp
  },
  components: { draggable, Draggable }
}
</script>

<style scoped>
  .category-container {
    padding: 4px 8px;

    border: 1px solid rgba(0,0,0, 0.2);
    border-radius: 4px;
  }

  .category-name {
    font-size: 28px;

    margin-bottom: 16px;
  }

  .field-container {
    padding: 4px 0px 4px 8px;

    background-color: white;
    border: 1px solid rgba(0,0,0, 0.2);
    border-radius: 4px;
  }

  .field-label {
    font-size: 16px;
  }

</style>