<template>
  <div class="container-fluid page-container">
    <div class="row content-container">
      <div class="col-8 d-flex flex-column gap-2 form-container">
        <template v-if="$route.params.formId && !doesFormExist">
          <material-alert color="warning">Form doesn't exist. To create a new form, head over <router-link to="/form-builder">here</router-link></material-alert>
        </template>
        <template v-else>
          <h1>{{ formTitle }}</h1>
          <div class="inputs">
            <label for="form-title">Title</label>
            <material-input 
              type="text" 
              id="form-title"
              :value="formTitle"
              @input="handleFormTitleChange"
              placeholder="Form Title"
            />
          </div>
          <draggable 
            class="draggable-container draggable-form-container d-flex flex-column gap-4" 
            v-model="form" 
            group="sections"
            item-key="id" 
          >
            <!-- wrap content in a single root element: https://github.com/SortableJS/vue.draggable.next/issues/24#issuecomment-859156290 -->
            <template #item="{element}">
              <div>
                <FormSection 
                  :section="element"
                  @change="handleSectionChange"
                  @remove="handleRemoveSection"
                />
              </div>
            </template>

            <template #footer>
              <MaterialButton @click="handleSubmitForm">Submit</MaterialButton>
            </template>
          </draggable>
        </template>
      </div>
      <div class="col-4 d-flex flex-column gap-2 inputs-container">
        <draggable
          v-model="sections"
          :group="{name: 'sections', pull: 'clone', put: false}"
          :sort="false"
          :clone="handleCloneFromSections"
          item-key="id"
        >
          <template #item>
            <div>
              <Draggable>
                <h2>Section</h2>
              </Draggable>
            </div>
          </template>
        </draggable>
        <InputsCategory 
          v-for="category in inputsCategories" 
          :key="category.category" 
          :name="category.category"
          :fieldsProp="category.fields"
          :handleClone="handleCloneFromInputs"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import MaterialInput from '@/components/MaterialInput.vue'
import MaterialButton from '@/components/MaterialButton.vue'
import MaterialAlert from "@/components/MaterialAlert.vue"
import draggable from 'vuedraggable'

import axios from '../../utils/axios'
import FormSection from '../components/FormSection.vue';
import InputsCategory from './components/InputsCategory.vue'
import Draggable from '../components/Draggable.vue'

export default {
  props: ['isTemplate'],
  data: () => ({
    inputsCategories: [],
    sections: [
      {
        type: 'section',
        id: Date.now() + 15
      }
    ],
    form: [],
    formTitle: 'Title',
    doesFormExist: false,
    formApiPrefix: '/v1/forms/',
  }),
  methods: {
    handleFormTitleChange(ev) {
      this.formTitle = ev.target.value
    },
    handleCloneFromInputs(el) {
      return { ...el }
    },
    handleCloneFromSections(el) {
      return { 
        ...el, 
        id: uuidv4(),
        title: 'Section',
        description: '',
        fields: [] 
      }
    },
    handleSectionChange(sectionId, data, fields) {
      const section = this.form.find(section => section.id === sectionId)

      if (data) {
        const sectionI = this.form.map(section => section.id).indexOf(sectionId)
        this.form.splice(sectionI, 1, { ...section, ...data })
      }

      if (fields) {
        section.fields = fields
      }
    },
    handleRemoveSection(sectionId) {
      const sectionI = this.form.map(el => el.id).indexOf(sectionId)
      this.form.splice(sectionI, 1)
    },
    async handleSubmitForm() {
      // remove fields that are only needed for state management
      const formData = this.form.map(section => {
        const fields = section.fields.map(field => {
          const _field = { ...field }

          return _field
        })
        
        const _section = { ...section, fields }
        delete _section.id
        delete _section.type
        
        return _section
      })
      
      console.log(JSON.stringify(formData, null, 2))
      
      if (this.$route.params.formId && this.doesFormExist) {
        const res = await axios.post(`${this.formApiPrefix}${this.$route.params.formId}`, {
          id: this.$route.params.formId,
          form_url: '',
          user_id: 0,
          is_active: true,
          title: this.formTitle,
          body: JSON.stringify(formData)
        })

        console.log('FormBuilder.handleSubmitForm, form exists - res:', res)
      } else {
        const res = await axios.post(this.formApiPrefix, {
          id: '',
          form_url: '',
          user_id: 0,
          is_active: true,
          title: this.formTitle,
          body: JSON.stringify(formData),
        })

        console.log('FormBuilder.handleSubmitForm, new form - res:', res)
      }
    }
  },
  async beforeMount() {
    if (this.isTemplate) {
      this.formApiPrefix = '/v1/form-templates/'
    }

    const {data: inputsCategoriesData } = await axios.get('/v1/forms/fields')

    this.inputsCategories = inputsCategoriesData

    if (!this.$route.params.formId) return
    
    const { data: dataForm } = await axios.get(`${this.formApiPrefix}${this.$route.params.formId}`)

    if (!dataForm) {
      this.doesFormExist = false
      return
    }

    const formData = JSON.parse(dataForm.body)
    // Add ids to the data
    const formState = formData.map(section => {
      const fields = section.fields.map(field => {
        return {...field, id: uuidv4()}
      })

      return {...section, fields, id: uuidv4()}
    })

    this.doesFormExist = true
    this.formTitle = dataForm.title
    this.form = formState
  },
  components: { 
    MaterialInput,
    MaterialButton, 
    MaterialAlert,
    draggable, 
    InputsCategory,
    FormSection,
    Draggable,
  }
}
</script>

<style scoped>
.page-container {
  /* full-height - header - footer */
  height: calc(100vh - 75px - 58px);
}

.content-container {
  height: 100%;
}

.form-container, .inputs-container {
  height: 100%;
  overflow-y: auto;
}

.inputs {
  display: grid;
  grid-template-columns: 150px 1fr;

  align-items: center;
  row-gap: 8px;

  margin-bottom: 8px;
}

.draggable-form-container {
  flex-shrink: 0;
  min-height: calc(100% - 140px);
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0,0,0, 0.2);
  border-radius: 4px;
}

.draggable-form-container button {
  margin-top: auto;
}
</style>
